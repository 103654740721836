import React from 'react';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import cookie from 'react-cookie';
import moment from 'moment';
import { History } from 'history';

import AuthService from '~/utils/AuthService';
import Helpers from '~/utils/Helpers';
import { isMSAAccepted, isTrialExpiredPlanChanged } from '~/utils/NavHelpers';
import { initializePendo } from '~/utils/PendoHelper';

import {
  IS_NEW_ACCOUNT,
  REDIRECT_URL,
  SAML_EMAIL,
  SESSION_ID_HASH_RE,
  USER_TYPES,
  veracodeUpsellUrl,
} from '~/constants/ModelConstants';

import * as billingActions from '~/actions/billing';
import * as myActions from '~/actions/me';
import * as teamActions from '~/actions/team';
import * as toastrActions from '~/actions/toastr';
import * as userStatusActions from '~/actions/userStatus';
import * as featureFlagActions from '~/actions/featureFlag';
import * as vcPageActions from '~/actions/vcAppActions/vcPageActions';
import * as vcNavActions from '~/actions/vcAppActions/vcNavActions';
import * as vcMeActions from '~/actions/vcAppActions/vcMeActions';

export const UPDATE_PRE_APP_STATUS = 'UPDATE_PRE_APP_STATUS';

export const checkUserStatus = (history: History) => (dispatch: Dispatch) => {
  const { location } = history;
  const { pathname, search } = location;
  const hash = window?.location?.hash;
  const hashMatch = SESSION_ID_HASH_RE.exec(hash);

  dispatch(userStatusActions.fetchUserStatus()).then(res => {
    const { roles } = res;
    const loggedIn = roles?.includes('USER');
    const authToken = AuthService.getAuthToken();
    const redirectUrl = localStorage && localStorage.getItem(REDIRECT_URL);
    const isNewAccount = cookie.load(IS_NEW_ACCOUNT);
    const isAuthenticatedSourceClearUser =
      authToken && loggedIn && roles.includes(USER_TYPES.SOURCECLEAR_USER);
    const isAuthenticatedVeracodeUser = loggedIn && roles.includes(USER_TYPES.VERACODE_USER);
    if (isAuthenticatedSourceClearUser || isAuthenticatedVeracodeUser) {
      if (hashMatch && hashMatch.length) {
        const sessionToken = hashMatch[1] || '';

        dispatch(userStatusActions.fetchUserStatusWithTokenSwap(sessionToken)).then(() => {
          dispatch(loadApp({ history, pathname, redirectUrl, isNewAccount }));
        });
      } else {
        dispatch(loadApp({ history, pathname, redirectUrl, isNewAccount }));
      }
    } else {
      if (hashMatch && hashMatch.length) {
        // you're logged in, time to swap your token and get you on your way
        const sessionToken = hashMatch[1] || '';

        AuthService.setSessionToken(sessionToken);
        dispatch(userStatusActions.fetchUserStatusWithTokenSwap(sessionToken)).then(res => {
          const { roles = [] } = res;

          if (roles.includes('2FACTOR')) {
            // you're 2factor and we don't remember this computer
            // sending you to /two-factor
            history.push('/twofactor');
            dispatch(updatePreAppStatus(true));
            return;
          } else {
            // check for two-factor cookie (remember this computer) and then finish the login process
            const twoFactor = AuthService.getTwoFactor();
            const config = twoFactor ? { headers: { 'x-auth-twofactor': twoFactor } } : {};

            dispatch(loadApp({ history, pathname, redirectUrl, isNewAccount, config }));
          }
        });
      } else {
        // you're not logged in so we'll check if the page you're attempting to visit is a "public" page
        const publicPathnames = [
          '/login',
          '/unsubscribe',
          '/twofactor',
          '/saml',
          '/signup',
          '/forgot-password',
          '/password-reset',
          '/accept-invite',
          '/find-my-org',
          '/billing/email-verify',
          '/billing-confirm',
          '/org-moved',
          '/plan-unsupported',
        ];

        if (!publicPathnames.includes(pathname)) {
          // this means you're logged out, but you're trying to access a logged in page so we'll save the redirect URL and redirect to '/login' (or '/saml')
          if (localStorage && pathname !== '/404') {
            localStorage.setItem(
              REDIRECT_URL,
              `${pathname}${search !== '?login=saml' ? search : ''}`
            );
          }

          history.replace(search.includes('login=saml') ? '/saml' : '/login');
        }

        // if the user has made it here - they're safely headed to a public page
        dispatch(updatePreAppStatus(true));
      }
    }
  });
};

export const loadApp = options => (dispatch: Dispatch, getState) => {
  dispatch(loadInitialData()).then((res = []) => {
    // Perform feature flag checks and dispatch org-related actions based on enabled features
    dispatch(featureFlagActions.processOnOrgLoad());

    const [me, teams] = res;

    const mePermissions = me && me.permissions;
    const { organization = {}, eula = {}, roles = [] } = me;
    const { welcomeMode, id: orgId, license = {}, permissions = {} } = organization;
    const { sca } = mePermissions;
    const { billing } = permissions;
    const { planType } = license;
    const { history, redirectUrl, isNewAccount } = options;
    const { myState } = getState();
    const { fetchMeFailed } = myState;

    const isVeracodeUser: boolean = roles.includes('VERACODE_USER');

    if (billing) {
      dispatch(billingActions.fetchBillingDashboard(orgId)).then((res = {}) => {
        dispatch(handleBillingDashboardResponse(res));

        if (planType === 'OPEN') {
          dispatch(billingActions.handleBillingSuccess(res, history));
        }
      });
    }

    localStorage && localStorage.removeItem && localStorage.removeItem(SAML_EMAIL);

    if (isVeracodeUser) {
      dispatch(loadVeracodeInitialData(sca)).then(() => {
        const { vcNavState } = getState();
        const { navigation } = vcNavState;
        const { platform } = navigation;

        if (!sca) {
          window.location.replace(`${platform}${veracodeUpsellUrl}`);
        } else {
          dispatch(vcPageActions.updateVCPageVisibility(true));
          dispatch(updatePreAppStatus(true));
          initializePendo({ me, navigation });
        }
      });
      return;
    } else if (fetchMeFailed) {
      history.push('/');
      dispatch(updatePreAppStatus(true));
    } else if (!isMSAAccepted(eula)) {
      history.push('/terms');
      dispatch(updatePreAppStatus(true));
      return;
    } else if (isTrialExpiredPlanChanged(organization)) {
      history.push('/trial-expired');
      dispatch(updatePreAppStatus(true));
      return;
    } else if (welcomeMode) {
      // When in welcome mode, send user to /welcome page if the first of the workspaces is administered by them.
      // If they don't administer the first of existing workspace, send them to /issues
      // If no workspace existing at all, load /no-workspaces page
      const maybeIssuesPath = teams.length ? '/portfolio' : '/no-workspaces';
      const url =
        teams.length && Helpers.hasManageAgentPermissions({ teams: teams[0].team })
          ? `/workspaces/${teams[0].team.id}/welcome`
          : maybeIssuesPath;

      history.push(url);
      dispatch(updatePreAppStatus(true));
      return;
    } else if (redirectUrl) {
      localStorage.removeItem(REDIRECT_URL);
      history.push(redirectUrl);
      dispatch(updatePreAppStatus(true));
      return;
    } else if (isNewAccount) {
      AuthService.handleNewAccountCookie();
      history.push(teams.length ? '/portfolio' : '/no-workspaces');
      dispatch(updatePreAppStatus(true));
      return;
    }

    dispatch(updatePreAppStatus(true));
    // if user has made it here - they're all set
  });
  // }
};

export const handleBillingDashboardResponse = billingData => (dispatch: Dispatch) => {
  const paymentMethod = billingData['payment-method'] || {};
  const { hasPaymentFailure } = paymentMethod;

  const {
    warnPercentScan,
    warnScanLimit,
    warnScanOverage,
    allowOverages,
    scanUsage = [],
  } = billingData;
  const plan = billingData.plan || {};
  const { scanLimit = 0 } = plan;

  // payment method failure toast
  if (hasPaymentFailure) {
    dispatch(
      toastrActions.addToastr({
        id: 'PAYMENT_FAILURE',
        level: 'error',
        title: 'Problem with your payment method',
        message: (
          <div>
            Your last invoice could not be paid. Please{' '}
            <Link
              to="/org/settings/billing"
              onClick={() => dispatch(toastrActions.removeToastr({ id: 'PAYMENT_FAILURE' }))}
              className="link--obvious"
            >
              update your payment method
            </Link>{' '}
            to avoid service interruption.
          </div>
        ),
      })
    );
  }

  if (warnPercentScan || warnScanOverage || warnScanLimit) {
    // redundant for clarity's sake - just warnPercentScan would suffice
    const endOfMonth = moment().add(1, 'months').date(1).format('MMMM D, YYYY');
    const options: {
      message: string | JSX.Element;
      title: string;
      level: string;
    } = {
      message: '',
      title: '',
      level: '',
    };

    // order is important here
    if (warnScanOverage) {
      options.message = allowOverages ? (
        `Your organization has exceeded the scan limit for this billing cycle, which ends ${endOfMonth}. You can scan at an additional fee until your next cycle begins. Upgrade to avoid these fees.`
      ) : (
        <div>
          Your organization has reached the scan limit for this billing cycle, which ends{' '}
          {endOfMonth}. No scans can be performed. To resume scanning, please upgrade to an
          Enterprise plan or wait until the next billing cycle.
        </div>
      );
      options.title = `Monthly limit exceeded`;
      options.level = `error`;
    } else if (warnScanLimit) {
      options.message = allowOverages ? (
        `Your organization has reached the scan limit for this billing cycle, which ends ${endOfMonth}. You can scan at an additional fee until your next cycle begins. Upgrade to avoid these fees.`
      ) : (
        <div>
          Your organization has reached the scan limit for this billing cycle, which ends{' '}
          {endOfMonth}. No scans can be performed. To resume scanning, please upgrade to an
          Enterprise plan or wait until the next billing cycle.
        </div>
      );
      options.title = `Monthly limit reached`;
      options.level = `error`;
    } else if (warnPercentScan) {
      const scansUsed = Helpers.calculateScansUsed(scanUsage);
      const scanPercentage = ((scansUsed / scanLimit) * 100).toFixed(0);

      options.message = allowOverages
        ? `Your organization has used ${scanPercentage}% of its scans this billing cycle, which ends ${endOfMonth}. Upgrade to avoid incurring fees.`
        : `Your organization has used ${scanPercentage}% of its scans this billing cycle, which ends ${endOfMonth}. Upgrade to keep working uninterrupted.`;
      options.title = `Approaching monthly limit`;
      options.level = `warning`;
    }

    dispatch(
      toastrActions.addToastr({
        ...options,
        id: 'USAGE_ALERT',
        callback: () => {
          dispatch(
            toastrActions.removeToastr({
              id: 'USAGE_ALERT',
            })
          );
          window.location.href = 'mailto:sales@sourceclear.com';
        },
        callbackText: 'Contact Us',
      })
    );
  }
};

export const loadInitialData = (config = {}) => (dispatch: Dispatch) => {
  return Promise.all([dispatch(myActions.fetchMe()), dispatch(teamActions.fetchTeams(config))]);
};

export const loadVeracodeInitialData = (sca: boolean) => (dispatch: Dispatch) => {
  if (sca) {
    return Promise.all([
      dispatch(vcNavActions.fetchVeracodeNav()),
      dispatch(vcMeActions.gatherVeracodeMePermissions()),
    ]);
  } else {
    return dispatch(vcNavActions.fetchVeracodeNav());
  }
};

export const updatePreAppStatus = status => ({
  type: UPDATE_PRE_APP_STATUS,
  status,
});
