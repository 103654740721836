import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { getNavFooter } from '~/utils/navServiceHelper';
import { VCNavState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import classnames from 'classnames';

interface VCFooterProps {
  vcNavState: VCNavState;
  isScaPlatformUIOpen: boolean;
}

class VCFooter extends Component<VCFooterProps, {}> {
  render() {
    const { vcNavState, isScaPlatformUIOpen } = this.props;
    const { navigation } = vcNavState;
    const footer = getNavFooter(navigation);
    const { activity_timestamp: activityTimestamp = '', activity_ip: activityIP = '' } = footer;
    const lastLogin = moment(new Date(parseInt(activityTimestamp)))
      .tz('America/New_York')
      .format('M/D/YY h:mm A z');
    const lastIP = activityIP;
    const currentYear = new Date().getFullYear();
    return (
      <footer
        className={classnames(
          'flex--footer vcFooter zIndex-1--screenForeground',
          isScaPlatformUIOpen ? 'footer-dark-style' : 'footer-vosp-style'
        )}
      >
        <ul>
          <li className="first">© Veracode, Inc. 2006 - {currentYear}</li>
          <li>
            <a
              href="https://analysiscenter.veracode.com/auth/docs/security_agreement.jsp"
              target="_blank"
              rel="noopener noreferrer"
            >
              Usage Guidelines
            </a>
          </li>
          <li>
            <a
              href="https://www.veracode.com/responsible-disclosure/responsible-disclosure-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Responsible Disclosure Policy
            </a>
          </li>
          <li>
            <a id="documentationLink" href="https://docs.veracode.com/">
              Documentation
            </a>
          </li>
          <li>
            <a
              id="supportCaseLink"
              href="mailto:support@veracode.com?Subject=New%20Agent%2DBased%20Scan%20Support%20Request"
            >
              Contact Support
            </a>
          </li>
        </ul>
        <div className="footer-secondary">
          {lastLogin && `Last account activity on ${lastLogin}`}
          {lastIP && ` from IP: ${lastIP}`}
          {(lastLogin || lastIP) && <span>&nbsp;&nbsp;&nbsp;</span>}
          For use under U.S. Pat. Nos 9,672,355, 9,645,800, 9,405,906, 9,286,063, 9,207,920,
          9,195,833, 8,365,155, 7,752,609, and 5,854,924, and patents pending.
        </div>
      </footer>
    );
  }
}
function mapStateToProps(state: VCFooterProps) {
  return {
    vcNavState: state.vcNavState,
  };
}
export default connect(mapStateToProps)(VCFooter);
